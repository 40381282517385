#intro {
  height: calc(100vh + 4rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.bg {
  position: absolute;
  top: 2rem;
  right: 0;
  z-index: -1;
  object-fit: cover;
  height: 100vh;
}

.intriContent {
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hello {
  font-size: 1.75rem;
  font-weight: 100;
}

.introName {
  color: #3ace12;
}

.intriPara {
  font-size: small;
  font-weight: 300;
  letter-spacing: 1px;
}

.btn {
  background: white;
  margin: 1rem 0;
  padding: 0.75rem;
  border: none;
  border-radius: 2rem;
}

.btn img {
  margin-bottom: -2px;
}

@media screen and (max-width: 480px) {
  .bg {
    right: -50vw;
  }
  .intriContent {
    margin-top: -5rem;
  }
}
