#skills {
  margin: 0 auto;
  min-height: calc(100vh -4rem);
  width: 100vw;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}

.skillsTitle {
  margin: 1rem 0;
  font-size: 3rem;
}

.skillsDesc {
  font-weight: 300;
  font-size: 1rem;
  padding-bottom: 15px;
}

.skillsImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  max-width: 65rem;
}

.skillsImg {
  object-fit: cover;
  height: 15rem;
  margin: 0.5rem;
  background: white;
}

.skillBtn {
  margin: 3rem 0;
  padding: 0.5rem 2.5rem;
  border: none;
  border-radius: 2rem;
  background-color: white;
  font-size: 1rem;
}

@media screen and (max-width: 585px) {
  .skillsImg {
    height: 48vw;
  }
}
