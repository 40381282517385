#works {
  overflow: hidden;
  width: 100vw;
  max-width: 65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.workTitle {
  font-size: 3rem;
  font-weight: 600;
  padding-top: 3rem;
  margin-bottom: 1.5rem;
}

.workDesc {
  font-weight: 300;
  font-size: 1rem;
  max-width: 51rem;
  padding: 0 2rem;
}

.workBars {
  margin: 1.5rem;
  width: 100vw;
  max-width: 80%;
  text-align: left;
}

.workBar {
  display: flex;
  margin: 1rem;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  background: rgb(50, 50, 50);
}

.workBarImg {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin-right: 2rem;
}

.workBarText > p,
.workBarText > a {
  font-size: 0.8rem !important;
  font-weight: 100;
}

@media screen and (max-width: 720px) {
  .workBarText > p {
    font-size: 2vw !important;
  }
}

a {
  color: white;
}

@media screen and (max-width: 480px) {
  .workBarText > p {
    font-size: 3vw !important;
  }

  .workBarImg {
    height: 2.25rem;
    width: 2.25rem;
  }

  .workBarText > h2 {
    font-size: 5vw;
  }
}
